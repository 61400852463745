//@import '../../variables.scss';
//@import '../../variables.scss';

.admin-section-header {
    padding: 0px;
    text-align: center !important;
    background-color: #0364b4;
    border-bottom: none !important;
    justify-content: center;
    color: white;
    height: 31px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

.cusor-pointer,
.cursor-pointer{
  cursor: pointer;
}

.font-12{
  font-size: 12px;
}

.heading {
  display: flex;

  b {
    border-bottom: 0.0625rem solid #FFA500;
    border-width: 0.1rem;
    margin-bottom: 0.8rem;
    font-size: 14px;
  }
}

.disabled{
  cursor: not-allowed !important;
  opacity: 0.3 !important;
}
