@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(300.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(400.woff) format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(700.woff) format('woff');
}
@font-face {
  font-family: 'AustinWorth';
  font-style: normal;
  font-weight: 400;
  src: local('AustinWorth'), local('AustinWorth'), url(AustinWorth.woff) format('woff');
}
@font-face {
  font-family: 'LeFrench';
  font-style: normal;
  font-weight: 400;
  src: local('LeFrench '), local('LeFrench'), url(LeFrench.woff) format('woff');
}
@font-face {
  font-family: 'PWSignaturetwo';
  font-style: normal;
  font-weight: 400;
  src: local('PWSignaturetwo'), local('PWSignaturetwo'), url(PWSignaturetwo.woff) format('woff');
}
