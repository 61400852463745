/* You can add global styles to this file, and also import other style files */
// @import "wijmo/styles/wijmo.css";
// @import "@grapecity/wijmo.styles/wijmo.css";
@import "./assets/scss/app.new.scss";
@import "./variables.scss";
@import "~@ng-select/ng-select/themes/default.theme.css"; //for ng-select
@import "../src/app/labadmin/components/share/directives/css/directives.scss";

.dataTables_filter input[type="search"] {
  border: 1px solid grey !important;
}
html{
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 14px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-size:14px;
}
.headerUpper th {
  text-transform: uppercase;
  font-size: 0.6rem;
  font-weight: 700;
  background-color: #333333;
  color: white;
}

.simpletable td {
  font-size: 0.75rem;
}

.vmd-print-sign:before {
  content: "\e91d";
}

div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}

.pager .page-item.active,
.pager>.active>a,
.pager>.active>span,
.pagestep .page-item.active,
.pagestep>.active>a,
.pagestep>.active>span {
  color: #fff !important;
  background-color: rgb(247, 122, 153) !important;
  border-color: rgb(247, 122, 153) !important;
}

.body-login {
  background-color: #fafafa !important;
}

body {
  height: auto;
  min-height: 100%;
  position: relative;
  overflow-x: hidden !important;
  background-color: #ffff !important;
}

.nav-active-danger .nav>li>a.active.nav-link,
.nav-active-danger .nav>li>a:hover.nav-link {
  background-color: white !important;
  border-radius: 0;
  color: black !important;
  border-bottom: 3px rgb(247, 122, 153) solid !important;
}

.nav-active-danger .nav>li>a.nav-link,
.nav-active-danger .nav>li>a.nav-link {
  border-bottom: 3px white solid !important;
}

.sebm-google-map-container {
  height: 300px;
}

.fullview>.sebm-google-map-container {
  height: 88vh;
}

.agm-info-window-content {
  width: 250px;
  overflow: hidden;
}

#snackbar {
  visibility: hidden;
  min-width: 340px;
  margin-left: -240px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 999;
  left: 90%;
  bottom: 30px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.7s 2.5s;
  animation: fadein 0.5s, fadeout 0.7s 2.5s;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.canvas {
  border: 1px solid #000;
}

#snackbarVal {
  visibility: hidden;
  min-width: 340px;
  margin-left: 89px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  z-index: 999;
  left: 90%;
  bottom: -48px;
  font-size: 17px;
}

#snackbarVal.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.7s 2.5s;
  animation: fadein 0.5s, fadeout 0.7s 2.5s;
}

.arrowPage {
  height: 30px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.vt-card:hover,
.vt-card:focus {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.vt-card.card-block {
  padding: 0.6rem;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.leg-details {
  display: table;
  width: 100%;
  margin: 20px 0;
}

.pipe {
  display: inline-block;
  margin: 0 5px;
}

.trip-list-sep {
  border-left: 1px #bebebe dashed;
  text-align: center;
  border-right: 1px #bebebe dashed;
}

.client-list-sep {
  border-left: 1px #bebebe dashed;
  border-right: 1px #bebebe dashed;
}

.ng-select.custom {
  border: 0px;
  font-family: inherit !important;
  min-height: 0px;
  border-radius: 0;
}

.ng-select.custom .ng-control {
  min-height: 0px;
  font-family: inherit;
  border-radius: 0;
}

.switcher.active {
  right: -2px !important;
}

.switcher {
  right: -320px !important;
  width: 320px !important;
}

// Scroll Width & Height

::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar
{
  width: 8px !important;
  height: 8px !important;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  background-color: #c1c1c1;
  border-radius: 6px;
}


.switcher .sw-btn {
  left: -42px !important;
}

.text-grey {
  color: lightgrey;
}

.text-stat {
  border: 1px lightgreen dotted;
  color: lightgreen;
  border-radius: 5px;
  font-weight: 500;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 0;
  text-align: center;
}

.gm-style-iw {
  left: 25px !important;
}

.grey {
  background-color: lightgrey !important;
}

.navside .nav li.active {
  // background-color: rgba(0,0,0,.065)!important;
  // border-left: 2px #f44455 solid!important;
  background-color: #5e35b1 !important;
}

.driver-panel-left {
  position: fixed;
  left: 0px;
  width: 250px;
  z-index: 100;
  top: 60px;
  height: 100%;
}

.driver-panel-left.med {
  width: 300px !important;
}

.panel-right {
  position: fixed;
  right: 0px;
  width: 250px;
  z-index: 100;
  top: 60px;
  height: 100%;
}

.panel-right.med {
  width: 300px !important;
}

.folded.show-text .nav>li>a .nav-text {
  font-size: 0.65rem !important;
}

#sidebar {
  /* for the animation */
  transition: margin 0.3s ease;
}

.t-collapsed {
  display: block !important;
}

.disclose {
  display: none;
}

.toggle-show-right {
  position: fixed;
  top: 62px;
  z-index: 2;
  right: 0;
  background-color: #fff;
  padding: 10px 12px;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.4);
  border-radius: 4px 0 0 4px;
  letter-spacing: 0.02em;
  font-weight: 300;
  overflow-x: hidden;
  transition: 0.5s;
}

.mydropdown.dropdown {
  display: none !important;
}

.mydropdown.dropdown.open {
  display: block !important;
}

.toggle-show-left {
  position: fixed;
  top: 74px;
  z-index: 2;
  left: 0;
  background-color: #fff;
  padding: 10px 12px;
  -webkit-box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.4);
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.4);
  border-radius: 0 4px 4px 0;
  letter-spacing: 0.02em;
  font-weight: 300;
  overflow-x: hidden;
  transition: 0.5s;
}

.align-center,
.search-form {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.showselected {
  border-left: 5px #2196f3 solid !important;
  background-color: aliceblue !important;
}

.unified-search-form {
  padding: 10px;
  display: flex;
  text-align: left;
  margin: 0;
  border-radius: 4px;
  width: 350px;
  min-height: 44px;
  box-sizing: border-box;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 65px;
  right: 10px;
  z-index: 1;
  background: #fff;
}

input[type="search"] {
  box-sizing: border-box;
  background-color: white;
  -webkit-rtl-ordering: logical;
  cursor: text;
  border: 1px white solid;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent !important;
}

.va_tab>.nav-active-info .nav-link.active,
.va_tab>.nav-active-info .nav>li.active>a {
  color: #00365f !important;
  background-color: #fff !important;
}

.va_tab>.nav-active-info .nav-link,
.va_tab>.nav-active-info .nav>li>a {
  color: #cec2e0 !important;
  background-color: #5d34b0 !important;
}

.maintabs>.nav-active-info .nav-link.active,
.maintabs>.nav-active-info .nav>li.active>a {
  color: #00365f !important;
  background-color: #fff !important;
}

.maintabs>.nav-active-info .nav-link,
.maintabs>.nav-active-info .nav>li>a {
  color: #000 !important;
  background-color: #dddddd !important;
}

.form-group {
  margin-bottom: 2px;
}

.md-form-group .form-control.has-error,
.form-group .form-control.has-error,
.form-group .has-error .input-group-addon {
  border-color: #F44336 !important;
  box-shadow: none;
  font-weight: 600;
}

.form-group .form-control.has-error,
.form-group .has-error .input-group-addon {
  border-color: #F44336 !important;
  box-shadow: none;
}

.form-group .has-error .help-block {
  color: #F44336 !important;
  font-weight: 600;
}

.form-group label {
  margin-bottom: 0px;
  color: #00365f !important;
}

.md-form-group label.has-error {
  color: #F44336 !important;
  font-weight: 600;
  opacity: 1;
}

.form-group label[data-error].has-error::after {
  position: relative;
  top: 0%;
  left: 2%;
  margin-bottom: 5px;
  /*margin-left: -80px;*/
  // padding-top: 2px;
  // padding-bottom: 2px;
  // padding-left:5px;
  // padding-right:5px;
  /*width: 160px;*/
  // -webkit-border-radius: 3px;
  // -moz-border-radius: 3px;
  // border-radius: 3px;
  //background-color: #F44336;
  /*background-color: hsla(0, 0%, 20%, 0.9);*/
  color: #F44336;
  content: "" attr(data-error);
  text-align: center;
  font-size: 10px;
  line-height: 1;
}

label.has-error {
  color: #F44336 !important;
  font-weight: 600;
}

/***/

/** begin css for loader */

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
}

.loading-bar {
  display: inline-block;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  animation: loading 1s ease-in-out infinite;
}

.loading-bar:nth-child(1) {
  background-color: #3498db;
  animation-delay: 0;
}

.loading-bar:nth-child(2) {
  background-color: #c0392b;
  animation-delay: 0.09s;
}

.loading-bar:nth-child(3) {
  background-color: #f1c40f;
  animation-delay: 0.18s;
}

.loading-bar:nth-child(4) {
  background-color: #27ae60;
  animation-delay: 0.27s;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1, 2.2);
  }

  40% {
    transform: scale(1);
  }
}

/***end css for loader**********/

/***Begin Grid lines**/

.gridlines {
  position: absolute;
  left: 9%;
  right: 6%;
  top: 0;
  bottom: 0;
}

.gridlines .gridline {
  border-left: 1px dashed #e7eaef;
  position: absolute;
  top: 1px;
  bottom: 0;
  width: 0;
  z-index: 4;
  font-size: 0.7rem;
}

.time_blocks {
  float: left;
  width: 91%;
}

.timeline_cover {
  position: relative;
  left: 52.3%;
  top: 30px;
  z-index: 300;
}

.timeline_ln {
  background-color: green;
  height: 4px;
  width: 50px;
  min-width: 50px;
}

.timeline_pos {
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #b311b5;
  position: relative;
  z-index: 9;
}

.timeline_pos:after {
  content: "";
  position: absolute;
  left: -6px;
  top: 6px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top-color: #b209b5;
}

.list-group-gap .list-group-item:hover {
  //background-color:#f5f5f5;
  background-color: #f7f7f7;
}

.top-navbar .nav-link.active {
  border-bottom: 4px #00b0ff solid !important;
  color: #00b0ff !important;
}

.left-navbar .nav-link.active {
  border-left: 2px #f77a99 solid !important;
  color: #f77a99 !important;
}

.top-navbar .nav-link .nav-text>span {
  text-transform: uppercase !important;
  font-size: 0.6rem !important;
}

/***End Gridlines**/

/***Begin Menu collapse*/
a[aria-expanded="false"] .menu__icon--open {
  display: block !important;
}

a[aria-expanded="false"] .menu__icon--close {
  display: none !important;
}

a[aria-expanded="true"] .menu__icon--open {
  display: none !important;
}

a[aria-expanded="true"] .menu__icon--close {
  display: block !important;
}

.nav-pills .nav-item.show .nav-link,
.nav-pills .nav-link.active {
  background-color: #f44455 !important;
  border-radius: 0;
}

.vline {
  height: 35px;
  top: 6px;
  border-left: 2px solid #9e9e9e !important;
  opacity: 0.4;
  padding-left: 5px;
  padding-right: 5px;
}

/***End Menu collapse*/

.loader-dot {
  display: inline-block;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #f34747;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  -moz-animation-name: opacity;
  -webkit-animation-name: opacity;
  animation-name: opacity;
  -moz-animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -moz-animation-delay: 100ms;
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
}

.splight .nav .prev {
  float: left;
}

.splight .nav .prev,
.splight .nav .next {
  position: relative;
  cursor: pointer;
  //height: 54px;
  border: 0;
  //width: 50px;
}

.splight .nav .thumbs {
  //border-left: 1px solid #4d4d4d;
  //border-right: 1px solid #4d4d4d;
  float: left;
  //height: 54px;
  overflow: hidden;
  //width: 400px;
}

.splight .nav .thumbs ul {
  width: 10000px;
  background-color: #3498db;
}


.splight .nav .thumbs li {
  cursor: pointer;
  float: left;
  list-style: none;
  padding: 15px;
  //width: 120px;
  //border-left: 1px #F44336 solid;
  font-size: 0.8rem;
  font-weight: 700;
  border-left: 1px solid #e8e7ec;
  border-top: 2px #fff solid;
}

.splight .nav .thumbs li:hover {
  background-color: rgba(0, 0, 0, 0.02);
  border-top: 2px #F44336 solid;
  color: #3498db;
}

.splight .nav .thumbs li.active {
  background-color: rgba(0, 0, 0, 0.02);
  border-top: 2px #F44336 solid;
  color: #3498db;
}

.driver {
  .f1 {
    padding: 10px;
    background: #fff;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }

  .f1 h3 {
    margin-top: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .f1 label {
    margin-bottom: 0;
  }

  .f1-steps {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
  }

  .f1-progress {
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #ddd;
  }

  .f1-progress-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    background: #f35b3f;
  }

  .f1-step {
    position: relative;
    float: left;
    width: 25%;
    padding: 0 5px;
  }

  .f1-step-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-top: 4px;
    background: #ddd;
    font-size: 16px;
    color: #fff;
    line-height: 40px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
  }

  .f1-step.activated .f1-step-icon {
    background: #fff;
    border: 1px solid #f35b3f;
    color: #f35b3f;
    line-height: 38px;
  }

  .f1-step.active .f1-step-icon {
    width: 48px;
    height: 48px;
    margin-top: 0;
    background: #f35b3f;
    font-size: 22px;
    line-height: 48px;
  }

  .f1-step p {
    color: #ccc;
  }

  .f1-step.activated p {
    color: #f35b3f;
  }

  .f1-step.active p {
    color: #f35b3f;
  }

  .f1 fieldset {
    display: none;
    text-align: left;
  }

  .f1-buttons {
    text-align: right;
  }

  .f1 button.btn.btn-next,
  button.btn.btn-next:focus,
  button.btn.btn-next:active:focus,
  button.btn.btn-next.active:focus {
    background: #f35b3f;
  }

  .f1 button.btn {
    min-width: 105px;
    height: 40px;
    margin: 0;
    padding: 0 20px;
    vertical-align: middle;
    border: 0;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    color: #fff;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    text-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
  }
}

.Vehicle {
  .f1 {
    padding: 10px;
    background: #fff;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }

  .f1 h3 {
    margin-top: 0;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .f1 label {
    margin-bottom: 0;
  }

  .f1-steps {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
  }

  .f1-progress {
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;
    height: 2px;
    background: #ddd;
  }

  .f1-progress-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    background: #f35b3f;
  }

  .f1-step {
    position: relative;
    float: left;
    width: 50%;
    padding: 0 5px;
  }

  .f1-step-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-top: 4px;
    background: #ddd;
    font-size: 16px;
    color: #fff;
    line-height: 40px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
  }

  .f1-step.activated .f1-step-icon {
    background: #fff;
    border: 1px solid #f35b3f;
    color: #f35b3f;
    line-height: 38px;
  }

  .f1-step.active .f1-step-icon {
    width: 48px;
    height: 48px;
    margin-top: 0;
    background: #f35b3f;
    font-size: 22px;
    line-height: 48px;
  }

  .f1 button.btn.btn-next,
  button.btn.btn-next:focus,
  button.btn.btn-next:active:focus,
  button.btn.btn-next.active:focus {
    background: #f35b3f;
  }

  .f1 button.btn {
    min-width: 105px;
    height: 40px;
    margin: 0;
    padding: 0 20px;
    vertical-align: middle;
    border: 0;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    color: #fff;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    text-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
  }
}

// toggle_button

.log_switch {
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 16px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 17em;
    left: 19em;
    bottom: 0;
    background-color: #f44455;
    width: 29px;
    height: 16px;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 3px;
    bottom: 4px;
    top: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.slider {
    background-color: #2196f3;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 9999 !important;
}

#wrap {
  display: inline-block;
  float: right;
  padding: 0;
  position: relative;
}

.globalsrch input[type="text"] {
  //height: 60px;
  //font-size: 55px;
  font-size: 16px;
  display: inline-block;
  border: none;
  outline: none;
  color: #555;
  padding: 3px;
  //padding-right: 60px;
  padding-right: 40px;
  width: 0px;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  z-index: 3;
  transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
  cursor: pointer;
}

.globalsrch input[type="text"]:focus:hover {
  border-bottom: 1px solid #bbb;
}

.globalsrch input[type="text"]:focus {
  //width: 700px;
  width: 300px;
  z-index: 1;
  border-bottom: 1px solid #bbb;
  cursor: text;
}

.globalsrch input[type="submit"] {
  //height: 67px;
  //width: 63px;
  height: 25px;
  width: 25px;
  display: inline-block;
  color: #F44336;
  float: right;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADNQTFRFU1NT9fX1lJSUXl5e1dXVfn5+c3Nz6urqv7+/tLS0iYmJqampn5+fysrK39/faWlp////Vi4ZywAAABF0Uk5T/////////////////////wAlrZliAAABLklEQVR42rSWWRbDIAhFHeOUtN3/ags1zaA4cHrKZ8JFRHwoXkwTvwGP1Qo0bYObAPwiLmbNAHBWFBZlD9j0JxflDViIObNHG/Do8PRHTJk0TezAhv7qloK0JJEBh+F8+U/hopIELOWfiZUCDOZD1RADOQKA75oq4cvVkcT+OdHnqqpQCITWAjnWVgGQUWz12lJuGwGoaWgBKzRVBcCypgUkOAoWgBX/L0CmxN40u6xwcIJ1cOzWYDffp3axsQOyvdkXiH9FKRFwPRHYZUaXMgPLeiW7QhbDRciyLXJaKheCuLbiVoqx1DVRyH26yb0hsuoOFEPsoz+BVE0MRlZNjGZcRQyHYkmMp2hBTIzdkzCTc/pLqOnBrk7/yZdAOq/q5NPBH1f7x7fGP4C3AAMAQrhzX9zhcGsAAAAASUVORK5CYII=) center center no-repeat;
  background-size: 80%;
  text-indent: -10000px;
  border: none;
  position: absolute;
  top: 6px;
  right: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 0.4;
  transition: opacity 0.4s ease;
}

.globalsrch input[type="submit"]:hover {
  opacity: 0.8;
}

.searchtextbox {
  height: 40px;
  margin-top: 10px;
  border-radius: 2px;
  font-size: 16px;
  padding: 0 40px;
  border: 1px solid #fff;
  box-shadow: 0 1px 2px 0 #dadfe3, 0 2px 5px 0 rgba(0, 0, 0, 0.08);
}

.mat-mdc-row:hover {
  background-color: #f0f0f0;
  color: grey;
  cursor: pointer;
}

.vo_bkgrd {
  background-color: #dcccf8;
}

.vo_btn.md-btn {
  transition: none !important;
}

.vo_btn.md-btn.md-flat:not([disabled]):focus,
.vo_btn.md-btn.md-flat:not([disabled]):hover {
  background-color: #00b0ff !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.vo_filter_btn.md-btn.md-flat:not([disabled]):focus,
.vo_filter_btn.md-btn.md-flat:not([disabled]):hover {
  background-color: rgba(158, 158, 158, 0.2) !important;
  text-transform: none !important;
  color: #000 !important;
}

.vo_filter_btn.md-btn.md-flat {
  text-transform: none !important;
  letter-spacing: 1px;
}

.login_btn {
  background-image: linear-gradient(90deg, #48c4e8 28%, #00ffe2 100%);
  font-size: 18px;
  color: #fff;
}

.login_btn:hover {
  color: #f35b3f;
}

.vo_btn {
  font-size: 0.75rem;
  text-align: center;
  letter-spacing: 1px;
}

.md-input:focus,
.md-input.focus {
  border-color: #00b0ff !important;
}

.md-input:focus~label,
.md-input.focus~label {
  color: #00b0ff !important;
}

.login_bg {
  background-image: url(/assets/images/login-bg1.png);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.cdk-overlay-container,
.cdk-overlay-pane {
  z-index: 9999 !important;
}

.fullwidth {
  width: 90%;
}

::ng-deep .mat-mdc-form-field {
  .mat-mdc-input-element {
    width: 200px !important;
  }
}

/*--------Begin Checkbox & radio button style------------*/
ul.ks-cboxtags {
  list-style: none;
  padding: 20px;
}

ul.ks-cboxtags li {
  display: inline;
}

ul.ks-cboxtags li label {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

// ul.ks-cboxtags li label {
//   padding: 8px 12px;
//   cursor: pointer;
// }

ul.ks-cboxtags li label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  content: "\f067";
  transition: transform 0.3s ease-in-out;
}

::ng-deep input[type="checkbox"] {
  background-color: #304065 !important;
}

ul.ks-cboxtags li input[type="checkbox"]:checked+label::before,
ul.ks-cboxtags li input[type="radio"]:checked+label::before {
  content: "\f00c";
  transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type="checkbox"]:checked+label,
ul.ks-cboxtags li input[type="radio"]:checked+label {
  border: 2px solid #304065;
  background-color: #304065;
  color: #fff !important;
  transition: all 0.2s;
}

// ul.ks-cboxtags li input[type="checkbox"],
// ul.ks-cboxtags li input[type="radio"] {
//   display: absolute;
// }

ul.ks-cboxtags li input[type="checkbox"],
ul.ks-cboxtags li input[type="radio"] {
  position: absolute;
  opacity: 0;
}

ul.ks-cboxtags li input[type="checkbox"]:focus+label,
ul.ks-cboxtags li input[type="radio"]:focus+label {
  border: 2px solid #e9a1ff;
}

/*--------End Checkbox & radio button style------------*/

.modal-xxl {
  max-width: 85%;
}

.modal-xxxl {
  max-width: 95%;
}

.vertical-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.date-width {
  width: 200px !important;
}

::ng-deep .smallpadding>.mat-mdc-expansion-panel-body {
  padding: 5px !important;
}

.first_row {
  color: #00365f;
  font-weight: 600;
}

.second_row {
  color: #5ca4d3;
}

.globalsearch.dropdown-menu .active {
  background-color: white !important;
  color: black !important;
}

.hrline {
  width: 100%;
  height: 1px;
  background-color: gray;
  margin-top: 8px;
  margin-bottom: 8px;
}

mat-expansion-panel.personalinfo {
  background: #e3f2fd !important;
}

mat-expansion-panel.contactinfo {
  background: #e8eaf6 !important;
}

mat-expansion-panel.guarantorinfo {
  background: #c4efef !important;
}

mat-expansion-panel.insuranceinfo {
  background: #f9fbe7 !important;
}

.resultinfo>.mat-mdc-expansion-panel-content>.mat-mdc-expansion-panel-body {
  padding: 10px !important;
}

.audittrail .tl-wrap {
  margin-left: 9em;
  padding: 10px 0 10px 20px;
}

.audittrail .tl-date {
  margin-left: -13.5em;
  width: 10.5em;
}

.audittrail .tl-header {
  width: 18em;
}

mat-form-field.width-xs>div.mat-mdc-form-field-wrapper {
  width: 100px !important;
}

mat-form-field.width-sm>div.mat-mdc-form-field-wrapper {
  width: 200px !important;
}

mat-form-field.width-md>div.mat-mdc-form-field-wrapper {
  width: 300px !important;
}

mat-form-field.width-lg>div.mat-mdc-form-field-wrapper {
  width: 400px !important;
}

mat-form-field.width-xl>div.mat-mdc-form-field-wrapper {
  width: 500px !important;
}

mat-form-field.width-xxl>div.mat-mdc-form-field-wrapper {
  width: 800px !important;
}

.nav-active-accent .nav-link.active {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #a88add !important;
  border-radius: 5px !important;
}

.white-overlay {
  background-color: rgba(255, 255, 255, 1) !important;
}

.search-results {
  height: 78vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-patients {
  height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.toptabhighlight>.btn:not([disabled]).active,
.toptabhighlight>.btn:not([disabled]):focus,
.toptabhighlight>.btn:not([disabled]):hover {
  box-shadow: inset 0 -10rem 0 #00b0ff !important;
  color: white !important;
}

.air-journey {
  width: 100%;
  height: 1px;
  background: #bebebe;
  margin: 10px auto 7px;
  position: relative;
}

.air-journey>span {
  width: 8px;
  height: 8px;
  background: #bebebe;
  position: relative;
  top: -3px;
  border-radius: 50%;
}

.air-journey .ytfi-flight {
  position: absolute;
  top: -7px;
  left: 45%;
  font-size: 16px;
  color: #666;
}

.del-model-header {
  padding: 0px;
  text-align: center !important;
  background-color: #00bcd4 !important;
  border-bottom: none !important;
  justify-content: center;
  color: white;
  height: 31px;
}

.dates_bar {
  position: absolute;
  background: #fff;
  z-index: 7;
  overflow: auto;
  width: 100%;
  padding-top: 6px;
  border-bottom: 1px solid #e6e6e6;
  color: #d5d5d5;
  font-size: 14px;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
  border-color: #dc3545;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

// Billing component Model
.modal.finalizes {
  position: fixed;
  top: 26%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.rate_input {
  max-width: 72%;
  height: 12px;
  font-size: 14px;
  border-radius: 3px !important;
}

.jaspero__confirmation_dialog {
  display: block !important;
  max-height: fit-content !important;
  top: 27% !important;
}

.importaccunt .ng-select .ng-control {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-spacing: 0;
  border-collapse: separate;
  min-height: 28px !important;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.customerfilter .ng-select .ng-control {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  border-spacing: 0;
  border-collapse: separate;
  min-height: 28px !important;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.corner-ribbon {
  position: relative;
  /*top: -14px;
    left: -14px;*/
  background: #169c2b;
  color: #fff;
  text-transform: uppercase;
  //padding: 2px 12px 2px 4px;
}

.corner-ribbon:after {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid #fff;
  border-bottom: 10px solid transparent;
}

.customerfilter .ng-select-dropdown-outer .ng-select-dropdown .ng-option {
  text-align: left;
}

.vo_btn>.md-btn.md-flat:not([disabled]):focus,
.md-btn.md-flat:not([disabled]):hover {
  background-color: #00b0ff !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

/****** Start of Newly added css for search********/

.ellipsisfull {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100% !important;
}

.ellipsisfull:hover {
  overflow: hidden;
}

:root .leftScroll {
  float: left;
  color: #00bcd4;
}

.rightScroll {
 // float: right;
  color: #00bcd4;
}

.tabContainer {
  height: auto !important;
}

.tabContainer .leftScroll {
  float: left;
  color: #31719b;
}

.tabContainer .leftScroll .btn {
  background: #ffffff;
  color: #31719b;
}

.tabContainer .leftScroll .btn:hover {
  color: #ff6600;
}

.tabContainer .leftScroll:focus {
  outline: solid #ff9900 1px !important;
  outline-offset: -4px;
}

.tabContainer .rightScroll {
  float: right;
  color: #31719b;
}

.tabContainer .rightScroll .btn {
  background: #ffffff;
  color: #31719b;
}

.tabContainer .rightScroll .btn:hover {
  color: #ff6600;
}

.tabContainer .rightScroll:focus {
  outline: solid #ff9900 1px !important;
  outline-offset: -4px;
}

.tabContainer .scroller {
  display: block;
  cursor: pointer;
}

.tabWrapper {
  // width: 1300px;
  overflow: hidden;
}

.tabsSet {
  left: 0px;
  min-width: 3000px;
  margin-left: 1px;
}

.tabContainer .nav-tabs {
  border-bottom: none;
}

/* line 3221, ../sass/common.scss */
.tabContainer .nav-item {
  display: table-cell;
  cursor: default;
  width: 150px;
  margin-top: 2px;
}

/* line 3229, ../sass/common.scss */
.tabContainer .nav-link {
  padding: 7px 5px;
  height: 31px;
  color: #31719b !important;
  border: 1px solid #31719b !important;
  background-color: #bad7ea;
  margin-right: 3px;
  top: 1px;
  cursor: pointer;
}

.scroller {
  display: block;
  cursor: pointer;
  margin-top: 15px;
  margin-right: 10px;
}

.b-warning {
  border-color: #00bcd4 !important;
}

.tabitem {
  display: table-cell;
  position: relative;
  cursor: grab;
  cursor: -webkit-grab;
  vertical-align: middle;
}

.flex-right {
  justify-content: flex-end;
  display: flex;
}

.ui-check>i,
.ui-check>span {
  margin-left: -20px;
}

.easyPieChart canvas,
.navbar .nav-text,
.sl-icon>i,
.ui-check>i {
  vertical-align: middle;
}

.ui-check {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}

.ui-check input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.ui-check input:checked+i:before {
  left: 4px;
  top: 4px;
  width: 6px;
  height: 6px;
  background-color: #0cc2aa;
}

.ui-check input:checked+span .active {
  display: inherit;
}

.ui-check input[type="radio"]+i,
.ui-check input[type="radio"]+i:before {
  border-radius: 50%;
}

.ui-check input[disabled]+i,
fieldset[disabled] .ui-check input+i {
  border-color: rgba(134, 143, 152, 0.2);
}

.ui-check input[disabled]+i:before,
fieldset[disabled] .ui-check input+i:before {
  background-color: rgba(134, 143, 152, 0.2);
}

.ui-check>i {
  width: 16px;
  height: 16px;
  line-height: 1;
  box-shadow: 0 0 1px rgba(120, 130, 140, 0.35);
  margin-left: -20px;
  margin-top: -2px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  background-clip: padding-box;
  position: relative;
}

.ui-check>i:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 0px;
  height: 0px;
  background-color: transparent;
}

.ui-check>span {
  margin-left: -20px;
}

.ui-check>span .active {
  display: none;
}

.ui-check.ui-check-color input:checked+i:before {
  background-color: #fff;
}

.ui-check-md input:checked+i:before {
  left: 6px;
  top: 6px;
}

.ui-check-md>i {
  width: 18px;
  height: 18px;
}

.ui-check-lg input:checked+i:before {
  width: 12px;
  height: 12px;
  left: 9px;
  top: 9px;
}

.ui-check-lg>i {
  width: 30px;
  height: 30px;
}

.v-xs {
  border-left: 1px solid #80808054;
  height: 36px;
}

.admin-model-header {
  padding: 0px;
  text-align: center !important;
  background-color: #00bcd4;
  border-bottom: none !important;
  justify-content: center;
  color: white;
  height: 31px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.admin-model-header-wrap {
  padding: 0px;
  text-align: center !important;
  background-color: #e3f2fd;
  border-bottom: none !important;
  justify-content: center;
  color: #000;
  height: 31px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.admin-model-header-txt {
  height: 13px;
  font-size: 17px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

:ng-deep.mdc-button {
  background-color: #00bcd4;
  color: white !important;
  cursor: pointer !important;
  font-size: 13px !important;
  font-weight: bold !important;
}

.mat-mdc-step-header .mat-mdc-step-icon-selected,
.mat-mdc-step-header .mat-mdc-step-icon-state-done,
.mat-mdc-step-header .mat-mdc-step-icon-state-edit {
  background-color: #00bcd4 !important;
}

.mat-mdc-horizontal-content-container {
  padding: 0px;
}

.mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-slide-toggle-bar {
  background-color: rgba(168, 252, 252, 0.54);
}

.mat-mdc-slide-toggle.mat-mdc-checked .mat-mdc-slide-toggle-thumb {
  background-color: #00bcd4;
}

.nav-active-primary .nav-link.active,
.nav-active-primary .nav>li.active>a {
  color: rgba(255, 255, 255, 0.87) !important;
  background-color: #0cc2aa !important;
}

::ng-deep.wj-cell.wj-align-right {
  text-align: left !important;
}

// modal-title bold
.modal-title {
  font-weight: bold !important;
}

.dropdown-list>ul.item2 {
  max-height: 140px !important;
}

::ng-deep.commentsTab {
  min-width: 0 !important;
  padding: 5px !important;
  font-size: medium;
  margin: 2px !important;
  border-right: 1px solid #0000;
}

// mat-mdc- tab for common
::ng-deep.mat-mdc-tab-label,
.mat-mdc-tab-label-active {
  padding: 10px !important;
  padding-left: 0 !important;
  margin: 3px !important;
  min-width: 138px !important;
}

.popup-dropdown {
  display: grid;
  justify-content: center;
}

.mat-mdc-tab-label {
  height: 48px !important;
  padding: 0 24px !important;
  cursor: pointer !important;
  box-sizing: border-box !important;
  opacity: 0.6 !important;
  text-align: center !important;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  white-space: nowrap !important;
  position: relative !important;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dx-url {
  box-shadow: #00000029;
  color: #304065 !important;
  letter-spacing: 0px !important;
  background: #f4f4f4 !important;
}

.dx-icon {
  padding: 7px;
  background: #304065;
  border-radius: 10px 0px 0px 10px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.tabsSet li:nth-child(3) {
  cursor: pointer;
  position: fixed;
  top: 16%;
  z-index: 2;
  right: 1%;
  overflow-x: hidden;
  transition: 0.5s;
  box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
  border-radius: 10px 0px 0px 10px;
}

.font-size {
  font-size: 12px !important;
  font-weight: 500;
  word-break: break-word;
  border-radius: 5px;
}

.nodata-design {
  margin: auto !important;
  background-color: yellow;
  padding: 10px  !important;
  font-size: 12px !important;
  font-weight: 600;
  border-radius: 5px;
}

.nodata-wrap {
  display: flex;
}

.no-data-wraps {
  margin-bottom: 10em;
  margin-top: 10em;
}

.mat-mdc-tab-label-container {
  z-index: 0 !important;
}

#myModal {
  z-index: 111111 !important;
}

.bordernone {
  border: none !important;
}

// all the wijmo grid custome size
.wj-flexgrid {
  height: 51vh !important;
  font-size: 12px !important;
  border: 1px solid #eee !important;
  min-width: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.wj-flex-grid {
  height: 51vh !important;
  font-size: 12px !important;
  border: 1px solid #eee !important;
  min-width: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
}

::ng-deep .gross-attr .mat-mdc-form-field-wrapper {
  padding-bottom: 0em !important;
}

.dash {
  visibility: hidden;
}

::ng-deep.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding: 0.5em 0 0.8em 0 !important;
}

::ng-deep .copyFromOrg input#mat-mdc-input {
  width: 92% !important;
}

.button-field-wrap {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
}

.chevron-align {
  float: right;
  font-size: 10px !important;
  cursor: pointer;
  opacity: 0.7;
}

.config-wrap input.mat-mdc-input-element {
  width: 95% !important;
}

.modal-footer {
  justify-content: center !important;
}

.copy-style {
  float: right;
  padding: 10px;
}

.cursor {
  cursor: pointer;
}

.copyIcon {
  height: 18px;
}

.mat-mdc-tab-body-content {
  // height: 100vh !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.mat-opt-align {
  min-height: 35px !important;
  font-size: 12px !important;
}

.browse {
  background: #00bcd4;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  width: fit-content;
}

.border-style {
  border-radius: 5px;
}

.warning-model-header {
  text-align: center !important;
  background-color: #00bcd4 !important;
  border-bottom: none !important;
  justify-content: center;
  color: white;
  height: 31px;
}

.high-value {
  color: green;
}

.warn-value {
  color: #ff9800;
}

.template-wrap {
  display: flex;
  justify-content: space-evenly;
  // margin: 20px 0px 20px 0px;
}

.text-admin,
.text-admin-hover a:hover {
  color: #00bcd4 !important;
}

.example-additional-selection {
  opacity: 0.75;
  font-size: 12px;
}

.modal-btn-wrap {
  height: 20vh !important;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.modal-btn-wraps{
  height: 20vh !important;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-heights {
  border: 1px outset #bfbfbf;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  // color: #717386;
  border-radius: 5px;
  overflow-x: auto;
  height: 40vh;
  padding: 10px;
}

.upload-wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  color: #717386;
  border-radius: 5px;
  height: 30vh;
  padding: 4px;
  background: #f4f4f4;
  font-size: 16px;
}

.get-app {
  opacity: 0.7;
}

.instr-size {
  font-size: 10px;
  color: #304065;
}

.img-wrap {
  opacity: 0.5;
}

.temp-font-size {
  font-size: 12px;
}

.head-font {
  font-size: 14px;
}

.error-msg {
  color: #F44336;
  font-size: 12px;
}

.required:after {
  content: "*";
  color: #F44336;
  font-weight: 600;
}

.error-field-wrap {
  display: flex;
  justify-content: start;
}

.button-wrap {
  display: flex !important;
  justify-content: end;
}

.word-wrap {
  word-wrap: break-word;
}

.align-left {
  display: flex;
  align-items: center;
}

.mat-mdc-opt-align {
  height: 35px !important;
  font-size: 12px !important;
}

.mat-mdc-input-element {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.text-wrap {
  background: #f4f4f4;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  // box-shadow: 0 3px 5px rgb(57 63 72 / 30%);
}

.form-font {
  font-size: 12px !important;
}

.wj-cell.wj-align-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.place-holder {
  height: 25px;
  font-size: 13px;
}

.shadow-textarea textarea.form-control {
  padding-left: 0.8rem;
}

// ::ng-deep .mat-mdc-checkbox-indeterminate.mat-mdc-accent .mat-mdc-checkbox-background,
// .mat-mdc-checkbox-checked.mat-mdc-accent .mat-mdc-checkbox-background {
//   background-color: #304065 !important;
// }

.wj-cell.wj-frozen:not(.wj-header):not(.wj-group):not(.wj-state-selected):not(.wj-state-multi-selected),
.wj-cell.wj-frozen.wj-alt:not(.wj-header):not(.wj-group):not(.wj-state-selected):not(.wj-state-multi-selected) {
  background: #e1e1e1;
}

.input-inline-wrap {
  width: 95% !important;
}

.alignText {
  text-align: center;
}

.sub-button {
  display: flex;
  justify-content: center;
}

.sub-button-space {
  display: flex;
  justify-content: space-between;
}

.edit-delete-btn {
  border: none;
  background: none;
  cursor: pointer;
  color: #000 !important;
}

.export-button-align {
  background-color: #00bcd4;
  color: white;
  justify-content: left;
}

.back-button-align {
  justify-content: right;
}

.submitButton-Align {
  display: flex;
  justify-content: center;
  padding: 12px;
}

::ng-deep .mat-mdc-dialog-container {
  -webkit-box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, .4) !important;
  -moz-box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, .4) !important;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, .4) !important;
  border-radius: 8px !important;
}

::ng-deep .genericModal {
  -webkit-box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, .4) !important;
  -moz-box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, .4) !important;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, .4) !important;
}

.modal-dialog {
  -webkit-box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, .4) !important;
  -moz-box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, .4) !important;
  box-shadow: 3px 3px 11px 0 rgba(0, 0, 0, .4) !important;
}

.casetype-margin {
  // margin: 0 12px;
  padding-left: 5px !important;
}

::ng-deep .activity-tracker.mat-mdc-ink-bar:nth-child(0) {
  width: 20% !important;
}

::ng-deep .activity-tracker.mat-mdc-ink-bar:nth-child(1) {
  width: 24% !important;
}

::ng-deep .activity-tracker.mat-mdc-ink-bar:nth-child(2) {
  width: 32% !important;
}

::ng-deep .activity-tracker.mat-mdc-ink-bar:nth-child(3) {
  width: 26% !important;
}

.img-style {
  width: 10px;
  cursor: pointer;
}

.close-img {
  position: absolute;
  top: -10%;
  right: 0%;
}

@media only screen and (max-width: 1280px) {

  // for screens below 760px
  .cal_pad {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
}

@media only screen and (min-width: 400px) and (max-width: 1280px) {

  // for screens below 760px
  .top_widget {
    width: 200px;
  }

  .cal_pad {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
}

// ::ng-deep .mat-mdc-checkbox-checked .mat-mdc-checkbox-layout .mat-mdc-checkbox-inner-container .mat-mdc-checkbox-background {
//   background-color: #304065 !important;
// }

// button padding for search
.btn {
  padding: 0.5rem !important;
}

// wijmo header design
.wj-cell.wj-header {
  background: #a6e5ed !important;
  color: #000 !important;
  font-weight: 700;
}

// pop-up standadized
.header-freeze {
  // position: fixed;
  width: 100%;
  z-index: 1111;
}

.header-wraps {
  margin-top: 60px !important;
  height: 81vh !important;
  overflow-y: auto;
}

#setupNewFacilityModal {
  margin-top: 150px !important;
}

.button-align {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.comments-area {
  height: 10vh;
  width: inherit;
  border-radius: 5px;
  border-color: #eae2e2;
  width: 100%;
}

#myModal1 {
  display: flex !important;
  background-color: #0c0c0c75;
}

#modaldialog1 {
  margin: auto;
  width: 40% !important;
  max-width: 40% !important;
}

.msg-wrap {
  word-break: break-word;
}

.body-message {
  justify-content: center;
  text-align: center;
  min-height: 15vh;
  overflow-y: auto;
}

@media only screen and (max-width: 800px) {
  .driver-panel-left {
    left: 0px;
  }

  .toggle-show-left {
    left: 0px !important;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1400px) {

  // for screens above 1281px and below 1400px
  .cal_pad {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }

  .top_widget {
    width: 20%;
  }
}

@media only screen and (min-width: 1401px) {

  // for screens above 1400
  .cal_pad {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }

  .top_widget {
    width: 20%;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1400px) {

  // for screens above 1281px and below 1400px
  .cal_pad {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}

// selectsheet popup
@media (min-width: 576px) {
  #selectSheetModal .modal-dialog {
    margin: 195px auto !important;
  }
}

@media (min-width: 576px) {
  #selectSheetModal1 .modal-dialog {
    margin: 195px auto !important;
  }
}

@media only screen and (min-width: 1401px) {

  // for screens above 1400
  .cal_pad {
    padding-left: 22px !important;
    padding-right: 22px !important;
  }
}
.mat-mdc-button-toggle-appearance-standard .mat-mdc-button-toggle-label-content {
  line-height: 30px !important;
}

::ng-deep.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

::ng-deep.mat-mdc-form-field-infix {
  min-height: 30px !important;
}

.admin-btn-success{
  height: $btn-height-size;
  background-color: $btn-background-color;
  font-size: $btn-font-size;
  color: $btn-text-color;
  padding-inline: $btn-padding;
  border: none;
  border-radius: 3px;
  box-shadow: 1px 1px 3px 1px grey;
  font-weight: 600;
  cursor: pointer;
}

::ng-deep #drop-down .mat-mdc-form-field-wrapper {
  padding-bottom: 0em !important;
  // border: 2px solid #c5c1c1;
  border-radius: 6px;
}

::ng-deep #drop-down .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-thick {
  color: #c5c1c1 !important;
}

::ng-deep #drop-down .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
  color: #c5c1c1 !important;
}

::ng-deep #drop-down .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding: 0.8em 0 0.8em 0 !important;
}
//tooltip z-index changed because pop having same z-index
.cdk-overlay-connected-position-bounding-box{
z-index: 10009 !important
}

::ng-deep .mdc-dialog__title::before{
  height: 0px !important;
}
.image-height {
  display: grid;
  justify-content: center;
  align-items: center;
  color: #717386;
  border-radius: 5px;
  height: 30vh;
  padding: 4px;
  background: #f4f4f4;
  font-size: 16px;
}

.dropdown-opt-wrap{
  min-height: 35px !important;
  font-size: 12px !important;
}

::ng-deep.mat-mdc-raised-button[disabled][disabled]{
  background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.38) !important;
}
::ng-deep .mat-mdc-form-field-icon-suffix, [dir=rtl] .mat-mdc-form-field-icon-prefix{
  display: flex !important;
}

::ng-deep .mat-mdc-form-field-icon-suffix.ng-star-inserted {
  display: flex !important
}

//for ng select styling

.ng-select .ng-select-container {
  height: 100% !important;
  min-height: 25px !important;
  min-width: 160px;
  max-height: 60px !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder{
  font-size: 12px;
}

#states>div>span.ng-arrow-wrapper {
  background-color: #376B96;
  height: 25px;
  border: thin solid #376B96;
}

#states>div>span>span.ng-arrow {
  top: 12%;
  right: -13%;
  width: 11px;
  border-color: #f8f2f2 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

#states>div>div>div.ng-input {
  top: 3px;
  padding-left: 1px;
  padding-right: 0;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input{
  padding-left: 5px !important;
  font-size: 12px;
}
.tooltip-list{
    white-space: pre;
    .mdc-tooltip__surface{
      text-align: left !important;
      max-width: 100% !important;
      overflow-y: auto !important;
  }
}.labadmin-spinner circle{
  stroke:#0364B4 !important;
}

//to avoid backdrop scroll when popup is opened
.cdk-global-scrollblock{
  overflow-y: hidden;
}

.customCEOverlayPanelClass {
  max-height: 180px !important;
}

.listingDropdownPanel {
  mat-pseudo-checkbox {
      display: none !important;
  }
}

.new-feature-tooltip .mdc-tooltip__surface{
  max-width: calc(100vw - 65vw) !important;
}

// No data 
.nodata-header-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45vh;
}

.nodata-wrapper {
  font-size: 30px;
  opacity: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
}

// label border 
.label_border {
  border-bottom: 2px solid #f68c00;
  font-size: 13px;
  display: inline-block;
  margin-bottom: 8px;
}

.dynamicHeightFix {
  max-height: calc(100vh - 140px);
  height: calc(100vh - 140px);
  overflow: auto;
  overflow-x: hidden;
}

.loader-aligned{
  margin: 20vh 0vw 0vh 25vw;
}
.menu-title {
  border-bottom: 2px solid #F68C00;
  padding-right: 17px;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 2px;
}

// :host ::ng-deep .mdc-checkbox .mdc-checkbox__native-control:focus~.mdc-checkbox__ripple {
//   display: none !important;
// }

.custom-matcheckbox .mdc-checkbox .mdc-checkbox__native-control:focus~.mdc-checkbox__ripple, .custom-matcheckbox .mdc-checkbox .mdc-checkbox__native-control {
  display: none !important;
}